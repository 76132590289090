import Layout from "../../../../src/components/common/layout/Layout";
import Faq from "../../../../src/contents/md/faq.mdx";
import { Container, Section } from "../../../../src/components/common/layout/Containers";
import MdxContainer from "../../../../src/components/common/layout/MdxLayout";
import SubscribeForm from "../../../../src/components/sections/SubscribeForm";
import * as React from 'react';
export default {
  Layout,
  Faq,
  Container,
  Section,
  MdxContainer,
  SubscribeForm,
  React
};