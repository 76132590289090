import { Youtube, Instagram, Mail } from "react-feather"
import React from "react"

const size = 40
export const SOCIALS = [
  {
    id: "youtube",
    href: "https://www.youtube.com/channel/UCrgmkseP89EXqSFOJLvYzMA",
    icon: <Youtube size={size} />,
    alt: "visite la chaine youtube de Sammantha Thom",
  },
  {
    id: "instagram",
    href: "https://www.instagram.com/sammanthathom",
    icon: <Instagram size={size} />,
    alt: "visite la chaine instagram de Sammantha Thom",
  },
  {
    id: "mail",
    href: "mailto:info@jeveillesurmoncoeur.com",
    icon: <Mail size={size} />,
    alt: "contacter nous par email de Veille sur ton coeur",
  },
]
