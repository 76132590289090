import React from "react"
import styled from "styled-components"

import { SOCIALS } from "../constants/socials"

const Socials = ({ column, center, follow }) => {
  const socialList = follow
    ? SOCIALS.filter((social) => social.id !== "mail")
    : SOCIALS
  return (
    <SocialList column={column} center={center}>
      {socialList.map((social) => (
        <li key={social.id}>
          <a
            target="blank"
            rel="noopener noreferrer"
            href={social.href}
            alt={social.alt}
            aria-label={social.alt}
          >
            {social.icon}
          </a>
        </li>
      ))}
    </SocialList>
  )
}

export default Socials

const SocialList = styled.ul`
  color: ${(props) => props.theme.color.primary};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  list-style: none;
  display: flex;
  padding: 0;
  //   justify-content: space-evenly;
  align-items: ${(props) => (props.center ? "center" : "left")};
  justify-content: ${(props) => (props.center ? "center" : "left")};
  a {
    color: ${(props) => props.theme.color.primary};
    :hover {
      color: ${(props) => props.theme.color.over};
    }
  }

  li {
    margin-right: 0.5rem;
  }
`
