import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { ExternalLink } from "react-feather";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Voici quelques questions posées par mes abonnées sur Instagram`}</h1>
    <h2>{`Le livre sera-t-il disponible en version numérique ?`}</h2>
    <p>{`Non. Le livre ne sera disponible qu’en format papier.`}</p>
    <h2>{`Où peut-on acheter le livre ?`}</h2>
    <p>{`Il est en vente en ligne sur tous les sites Amazon et à La Maison de la Bible (Montréal, Québec).
Pour une copie dédicacée, il faudrait me contacter directement à `}<a parentName="p" {...{
        "href": "mailto:info@jeveillesurmoncoeur.com"
      }}>{`info@jeveillesurmoncoeur.com`}</a></p>
    <h2>{`Le livre est-il en vente en Afrique ?`}</h2>
    <p>{`Oui. Il est disponible au Cameroun et en Côte d’Ivoire. Pour se procurer une copie, il faut écrire à `}<a parentName="p" {...{
        "href": "mailto:info@jeveillesurmoncoeur.com"
      }}>{`info@jeveillesurmoncoeur.com`}</a><br />{`
Je recherche activement des compagnies de transport qui assureraient la livraison dans d’autres pays d’Afrique.`}</p>
    <h2>{`Quel est le prix du livre ?`}</h2>
    <ul>
      <li parentName="ul">{`Canada: 21.99 $ CAD`}</li>
      <li parentName="ul">{`États-Unis: 21.99 $ USD`}</li>
      <li parentName="ul">{`Europe: 21.99 €`}</li>
      <li parentName="ul">{`Afrique centrale et Afrique de l’Ouest : 10 000 CFA`}</li>
    </ul>
    <p>{`Pour les autres devises, se référer au site Amazon qui dessert ton pays.`}</p>
    <h2>{`Le livre est-il publié à compte d’auteur (autoédition) ?`}</h2>
    <p>{`Oui.`}</p>
    <h2>{`En combien de temps as-tu écrit le livre ?`}</h2>
    <p>{`Je l’ai écrit en un peu plus de six semaines, je crois. Dans ce délai, je ne compte pas les relectures, les corrections, les révisions, la mise en page, etc.`}</p>
    <h2>{`Est-ce une autobiographie ?`}</h2>
    <p>{`Non, il ne s’agit pas d’une autobiographie. Cependant, je partage quelques témoignages personnels.`}</p>
    <h2>{`Comment t’est venue la conviction d’écrire ce livre ?`}</h2>
    <p>{`J’en parle dans ma vidéo YouTube `}<a href="https://youtu.be/3YkO8De33lg" target="_blank">{`Comment j’ai connu mon appel `}<ExternalLink size={15} mdxType="ExternalLink" /></a>{` que je t’invite à regarder. Pour résumer, après la publication de ma série de vidéos `}<a href="https://www.youtube.com/watch?v=EIGHa86OhB4&list=PLWdgh1v_-Rdr-BgF3GJsdzjYW25Hkbq9t" target="_blank">{`Dieu écrit mon histoire d’amour `}<ExternalLink size={15} mdxType="ExternalLink" /></a>{`, de nombreuses femmes m’ont demandé comment reconnaitre la bonne personne et faire le bon choix. Sachant que tout passe par une intimité avec Dieu (prière et lecture de la Parole), j’ai voulu encourager mes abonnées à prier pour leur futur mari comme je l’avais fait en 2017.
Mais, je n’arrivais pas à trouver la liste de sujets de prière que j’avais utilisée, alors j’ai décidé d’écrire la mienne. Quand j’ai commencé, Dieu m’a dit (témoignage intérieur) : « Tu n’écriras pas une liste, mais un livre ». Je n’avais aucune idée de ce que Dieu attendait réellement de moi, mais j’ai obéi : c’est ainsi que la liste de sujets de prière est devenue un livre de plus de 200 pages.`}</p>
    <h2>{`Pourquoi avoir écrit un livre sur la préparation au mariage ?`}</h2>
    <p>{`Parce que le Seigneur m’a demandé de le faire. D’ailleurs, pour être honnête, j’avais toujours pensé que mon premier livre (si vraiment je devais en écrire un) porterait sur la guérison émotionnelle. Cependant, je n’étais ni surprise ni « embarrassée » que Dieu m’appelle à écrire un tel ouvrage. En effet, j’ai été témoin de beaucoup de mariages brisés et dysfonctionnels parce que les personnes avaient mal choisi leur partenaire de vie ; si bien que j’avais moi-même juré ne jamais me marier ! Dieu m’a restaurée, il a renouvelé mon intelligence et il m’a conduite jusqu’au mari de ma destinée. Je suis convaincue que le Seigneur m’a choisie pour aider d’autres femmes à avoir le même témoignage et à faire un mariage heureux, en choisissant la bonne personne.`}</p>
    <h2>{`Faut-il jeûner pendant les 31 jours ?`}</h2>
    <p>{`Quand j’ai prié en 2017, j’avais jeûné ; toutefois, je jeûnais principalement dans le but de connaitre les projets de Dieu pour ma vie en général. Je voulais être plus sensible à sa voix et rester fixée sur lui (c’est là le but du jeûne).
Cela dit, s’il faut faire un jeûne en lisant mon livre, je suggère de le faire pendant les sept premiers jours du livre parce que pendant ces journées, tu iras en profondeur dans des temps de prière personnels pour disposer ton cœur, renouveler ton intelligence et entendre la voix de Dieu. Les vingt-quatre jours restants sont dédiés à intercéder pour ton futur mari, apprendre à faire le bon choix et prendre conscience de tes besoins par opposition à tes désirs.
Par ailleurs, beaucoup de lectrices ont témoigné que mon livre leur a aussi permis de prier pour leur caractère et leur croissance spirituelle. En effet, plusieurs sujets de prière pour leur futur époux s’appliquaient à elles également.`}</p>
    <h2>{`Quelle est ta vision ? Qu’espères-tu apporter à ta lectrice ?`}</h2>
    <p>{`J’espère aider ma lectrice à disposer son cœur à connaitre et recevoir le projet de mariage que Dieu a formé pour elle, à renouveler son intelligence quant au mariage et aux critères d’un bon mari selon Dieu, à prier pour son futur mari comme elle prie pour elle-même, et à faire le bon choix le moment venu.`}</p>
    <h2>{`Peut-on lire le livre même si nous ne sommes pas dans la saison du mariage ?`}</h2>
    <p>{`Je dirais même que c’est l’un des meilleurs moments ! Ce que Dieu te dira sur ton futur mari ne sera pas teinté par tes sentiments pour un homme ou par un des défis que présente le célibat (solitude, pression sociale et familiale, etc.). Aussi, tu seras pleinement disposée à recevoir le projet de mariage de Dieu et tu pourras évaluer, au travers des témoignages et des enseignements du livre, les points sur lesquels tu as encore besoin d’être renouvelée. C’est une bonne occasion (en plus de préparer ton mariage) de faire le point sur ta maturité spirituelle et ton caractère.`}</p>
    <h2>{`Le livre est-il exclusivement destiné aux célibataires ?`}</h2>
    <p>{`Des femmes mariées ont trouvé mon livre édifiant et pertinent pour elles aussi parce qu’il leur a permis de prier pour les qualités que leurs maris n’ont pas encore pleinement développées. De plus, plusieurs lectrices te diront que c’est d’abord un temps rafraîchissant avec Dieu, qui te conduira dans des sujets de prière pour toi-même que tu ne soupçonnes pas.`}</p>
    <h2>{`Est-ce que le livre explique comment discerner la volonté de Dieu pour le mariage ?`}</h2>
    <p>{`La première partie du livre s’intitule `}<em parentName="p">{`Entendre la voix de Dieu`}</em>{` et elle comporte deux chapitres : `}<em parentName="p">{`Sept manières dont Dieu te parle et Reconnaitre la voix de Dieu`}</em>{`. Tu reconnaitras des principes que j’ai déjà mentionnés sur ma chaine `}<a href="https://www.youtube.com/channel/UCrgmkseP89EXqSFOJLvYzMA" target="_blank">{`YouTube `}<ExternalLink size={15} mdxType="ExternalLink" /></a>{` et sur le site de `}<a href="https://jeveillesurmoncoeur.com/" target="_blank">{`Veille sur ton cœur `}<ExternalLink size={15} mdxType="ExternalLink" /></a>{`. Ces principes s’appliquent à discerner la volonté de Dieu dans tous les aspects de notre vie, y compris le mariage.`}</p>
    <h2>{`Quelles sont les différentes parties du livre ?`}</h2>
    <p>{`Le livre est divisé en six grandes parties, sans compter l’introduction et la conclusion (encouragements et conseils).`}</p>
    <ol>
  <li>Entendre la voix de Dieu</li>
  <li>Un cœur disposé et une intelligence renouvelée</li>
  <li>Un chrétien né de nouveau</li>
  <li>Un leader</li>
  <li>Un mari de bonne conduite</li>
  <li>Un mari attentif à tes besoins</li>
    </ol>
    <p>{`Chaque partie commence par une introduction et contient plusieurs chapitres qui sont appelés `}<em parentName="p">{`Jour`}</em>{`. Chaque jour commence avec un verset biblique, qui sera le socle sur lequel l’enseignement sera basé, et se termine par des sujets de prière.`}</p>
    <h2>{`Je suis tellement découragée par mes échecs amoureux. Ce livre me sera-t-il bénéfique ?`}</h2>
    <p>{`Dès l’introduction, j’explique pourquoi je ne croyais pas au mariage et pourquoi j’aurais préféré être la maitresse d’un homme plutôt qu’être son épouse. J’explique également que c’est au contact de Jésus que tout a changé : pas parce qu’il m’a fait rencontrer un homme spécial ni parce que j’ai finalement vu des mariages heureux, mais parce que je suis rentrée en relation avec le Dieu qui a institué le mariage, qui m’a montré ce qu’était le véritable amour, et qui m’a promis que je pouvais le vivre moi aussi. Alors oui, dans ce sens, le livre te sera bénéfique.`}</p>
    <h2>{`Si l’on n’a aucun moyen de rencontrer un homme, peut-on faire ces prières ?`}</h2>
    <p>{`Il faut déjà préciser que ce sont des sujets de prière et non des prières à réciter. Le Saint-Esprit te conduira dans la prière et tu sauras comment prier pour les sujets que j’énonce.
Ceci étant établi, le but de mon livre n’est pas de rencontrer quelqu’un, mais (entre autres) de connaitre le projet de mariage de Dieu pour ta vie, de prier pour ton futur mari (son cœur et son caractère) et de t’équiper pour reconnaitre la bonne personne le moment venu.`}</p>
    <h2>{`J’ai 19 ans, est-ce que ton livre est pour moi aussi ?`}</h2>
    <p>{`Une de mes proches amies s’est mise en relation à 19 ans et s’est mariée à 23 ou 24 ans ; elle se serait même mariée plus tôt, mais ses parents la jugeaient trop jeune. C’est pour dire que oui, à 19 ans, Dieu peut te révéler son projet de mariage pour toi et même t’envoyer la bonne personne. Il faudra simplement attendre d’avoir atteint une certaine maturité avant de vous marier. Le livre est pour toi aussi.`}</p>
    <h2>{`Te sens-tu apte pour affronter un tel sujet avec si peu d’expérience ? Mon entourage dit que c’est trop tôt`}</h2>
    <p>{`Voici ce que tu peux répondre à ton entourage de ma part : « Cela ne veut pas dire que nous puissions nous considérer par nous-mêmes à la hauteur d’une telle tâche ; au contraire, notre capacité vient de Dieu. » (2 Corinthiens 3.5)
C’est Dieu qui me qualifie et non mon « expérience ». Si c’est l’expérience qui qualifiait et non l’Esprit Saint, on jetterait à la poubelle les enseignements sur le mariage de l’apôtre Paul et de Jésus puisqu’ils ne se sont jamais mariés !
Et même si c’était l’expérience qu’il fallait prôner, je n’écris pas un livre sur le mariage, mais sur la préparation au mariage. Et je me suis préparée pendant 3 ans. Je suis demeurée fidèle à Dieu. J’ai reçu de lui tout ce qu’il m’avait promis. J’ai su reconnaitre le mari de ma destinée, et je vis pleinement dans cette destinée aujourd’hui.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      