import styled from "styled-components"

import { Container } from "../layout/Containers"

// `16px 0` : `24px 0`
export const Nav = styled.nav`
  padding: ${(props) => (props.scrolled ? `10px 0` : `7px 0`)};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: ${(props) =>
    props.scrolled || props.mobileMenuOpen ? `white` : null};
  transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    // background: white;
  }
`

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;

        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`

export const NavItem = styled.li`
  margin: 0 0.75em;
  font-family: ${(props) => props.theme.font.medium};
  ${(props) => props.theme.font_size.small};

  a {
    text-decoration: none;
    opacity: 0.9;
    color: ${(props) => props.theme.color.black.regular};
  }
  &.active {
    a {
      opacity: 1;
    }
  }
`

export const MobileMenu = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: ${(props) => props.theme.color.regular};
  text-align: center;
  li {
    ${(props) => props.theme.font_size.large};
  }
`

export const Brand = styled.div`
  font-family: ${(props) => props.theme.font.extrabold};
  ${(props) => props.theme.font_size.regular};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      color: ${(props) => props.theme.color.black.regular};
      text-decoration: none;

      img {
        width: 4rem;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    font-size: 16px;
  }
`
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    display: none;
  }

  a,
  button {
    font-family: ${(props) => props.theme.font.normal};
    ${(props) => props.theme.font_size.xsmall};
    color: white;
    background: ${(props) => props.theme.color.secondary};
    border-radius: 4px;
    padding: 10px 16px;
    text-transform: uppercase;
    font-size: 12px;
    text-decoration: none;
    :hover {
      background: ${(props) => props.theme.color.over};
    }
  }
`

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    display: block;
  }

  ${(props) =>
    props.hide &&
    `
    display: block;
    @media (max-width: ${props.theme.screen.xs}) {
      display: none;
    }
  `}
`
