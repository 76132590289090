import React, { Component } from "react"
import { Menu, X } from "react-feather"
import { Link } from "gatsby"

import Logo from "../../../assets/images/logo.png"
import { Container } from "../layout/Containers"
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  ActionsContainer,
} from "./styles"

const NAV_ITEMS = [
  { nav: "Livre" },
  { nav: "E-book" },
  { nav: "Auteure" },
  { nav: "Mon journal", link: "blog" },
  { nav: "Nous contacter", link: "contact", mobile: true },
  {
    nav: "Vers le mari de ma destinée",
    link: "vers-le-mari-de-ma-destinee",
    mobile: true,
  },
]

export default class Navigation extends Component {
  state = {
    mobileMenuOpen: false,
    hasScrolled: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return
    }
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuOpen: !prevState.mobileMenuOpen,
    }))
  }

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false })
    }
  }

  getNavAnchorLink = (item) => (
    <Link
      to={`/${item.link ? `${item.link}/` : `#${item.nav.toLowerCase()}`}`}
      onClick={this.closeMobileMenu}
    >
      {item.nav}
    </Link>
  )

  getNavList = ({ mobile = false }) => {
    let filteredNav = NAV_ITEMS
    if (!mobile) {
      filteredNav = NAV_ITEMS.filter((item) => !item.mobile)
    }
    return (
      <NavListWrapper mobile={mobile}>
        <ul>
          {filteredNav.map((navItem) => (
            <NavItem key={navItem.nav}>
              {this.getNavAnchorLink(navItem)}
            </NavItem>
          ))}
        </ul>
      </NavListWrapper>
    )
  }

  render() {
    const { mobileMenuOpen } = this.state

    return (
      <Nav
        {...this.props}
        scrolled={this.state.hasScrolled}
        mobileMenuOpen={mobileMenuOpen}
      >
        <StyledContainer>
          <Brand>
            <ul>
              <li>
                <Link to={`/`} onClick={this.closeMobileMenu}>
                  <img src={Logo} alt="logo" width="4rem" height="auto" />
                </Link>
              </li>
            </ul>
          </Brand>
          <Mobile>
            <button
              onClick={this.toggleMobileMenu}
              style={{ color: "black", background: "none" }}
            >
              {this.state.mobileMenuOpen ? (
                <X size={40} alt="close menu" />
              ) : (
                <Menu size={40} alt="open menu" />
              )}
            </button>
          </Mobile>
          <Mobile hide>{this.getNavList({})}</Mobile>
          <ActionsContainer>
            <Link to="/contact/">Nous contacter</Link>
          </ActionsContainer>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    )
  }
}
