import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import theme from "../../../assets/styles/theme"
import GlobalStyles from "../../../assets/styles/GlobalStyles"
import Navigation from "../navigation/Navigation"
import "../../../assets/styles/fonts/fonts.css"
import Footer from "../../sections/Footer"

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      <Navigation />
      {children}
      <Footer />
    </>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
