import React from "react"

import { TryItButton } from "../sections/sectionsStyles"
import { Form, Input } from "./formsStyles"

const Subscribe = ({
  buttonName,
  handleSubmit,
  handleInputChange,
  name,
  email,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Input
        placeholder="Nom"
        style={{ marginRight: "1rem" }}
        type="text"
        name="name"
        required
        value={name}
        onChange={handleInputChange}
      />
      <Input
        placeholder="Courriel"
        type="email"
        name="email"
        required
        value={email}
        onChange={handleInputChange}
      />
      <TryItButton type="submit">{buttonName}</TryItButton>
    </Form>
  )
}

export default Subscribe
