import styled from "styled-components"

const Container = styled.div`
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.75rem;
    line-height: normal;
  }

  margin-bottom: 4rem;
`

export default Container
