import styled, { css } from "styled-components"

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  width: 90%; // help to show on one line
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const InputStyle = css`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  width: 100%;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

export const Input = styled.input`
  ${InputStyle}
  height: 60px;
  line-height: 42px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
`

export const TextArea = styled.textarea`
  ${InputStyle}
  line-height: 1.5rem;
  margin-bottom: 2rem;
  height: auto;
`
