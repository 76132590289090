import axios from "axios"
import React from "react"
import styled from "styled-components"

import { Container, Section } from "../common/layout/Containers"
import SubscribeForm from "../forms/Subscribe"
import { Subtitle } from "./sectionsStyles"

const ADD_NEW_CONTACT_ENDPOINT = "/.netlify/functions/brevo-add-new-contact"

export default class Subscribe extends React.Component {
  responseMessage = {
    title: "",
    subtitle: "",
    background: "",
    color: "",
  }

  state = {
    formValues: { name: "", email: "" },
    sendStatus: { success: null, errorMsg: "" },
  }

  // componentDidUpdate() {
  //   if (this.success === true || this.success === false) {
  //     console.log("update", this.success)
  //     setTimeout(
  //       () =>
  //         this.setState((prevState) => ({
  //           sendStatus: {
  //             ...prevState.sendStatus,
  //             success: null,
  //           },
  //         })),
  //       6000
  //     )
  //   }
  // }

  handleSubmit = async (e) => {
    e.preventDefault()
    axios
      .post(ADD_NEW_CONTACT_ENDPOINT, this.getSubscribePayload())
      .then((_) => {
        this.resetForm()
        this.setState((prevState) => ({
          sendStatus: {
            ...prevState.sendStatus,
            success: true,
          },
        }))
      })
      .catch((error) => {
        let message = { success: false, errorMsg: "" }
        if (error.response?.data?.code === "duplicate_parameter") {
          message.errorMsg =
            "Vous êtes déjà inscrit(e) à notre liste de diffusion. "
        } else {
          message.errorMsg =
            "Une erreur est survenue. Veuillez vérifier votre courriel et réessayer."
        }

        this.setState((prevState) => ({
          sendStatus: {
            ...prevState.sendStatus,
            ...message,
          },
        }))
      })
  }

  resetForm = () => {
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        name: "",
        email: "",
      },
    }))
  }

  handleInputChange = (event) => {
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        [event.target.name]: event.target.value,
      },
    }))
  }

  getSubscribePayload = () => {
    return {
      email: this.state.formValues.email,
      attributes: {
        PRENOM: this.state.formValues.name,
      },
      listIds: process.env.GATSBY_BREVO_LIST_VDM_ID
        ? [+process.env.GATSBY_BREVO_LIST_VDM_ID]
        : [],
    }
  }

  render() {
    let { bgLight } = this.props
    let { success, errorMsg } = this.state.sendStatus

    if (success === false) {
      this.responseMessage.title = "Une erreur s'est produite :("
      this.responseMessage.subtitle = errorMsg
      this.responseMessage.background = "#f8d7da"
      this.responseMessage.color = "#721c24"
    } else if (success === true) {
      this.responseMessage.title = "Vous avez rejoint la communauté :)"
      this.responseMessage.subtitle =
        "Si vous n’avez pas reçu d’e-mail de confirmation, pensez à vérifier vos spams. Votre cadeau de bienvenue vous sera envoyé en même temps."
      this.responseMessage.background = "#d4edda"
      this.responseMessage.color = "#155724"
    }

    return (
      <StyledSection id="subscribe" anchor bgLight={bgLight}>
        <GetStartedContainer>
          <GetStartedTitle style={{ marginBottom: "0" }}>
            Rejoins la communauté
          </GetStartedTitle>
          <Subtitle style={{ marginBottom: "3rem" }}>
            Reçois gratuitement un cadeau de bienvenue*
          </Subtitle>
          {success !== null && (
            <Subtitle
              style={{
                marginBottom: "3rem",
                backgroundColor: `${this.responseMessage.background}`,
                color: `${this.responseMessage.color}`,
                padding: " .75rem 1.25rem",
                border: "1px solid transparent",
                borderRadius: ".25rem",
                textAlign: "center",
                marginTop: "0",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              <span style={{ display: "block", fontFamily: "HK Grotesk Bold" }}>
                {this.responseMessage.title}
              </span>
              {this.responseMessage.subtitle}
            </Subtitle>
          )}
          <SubscribeForm
            buttonName="S'incrire"
            handleSubmit={this.handleSubmit}
            handleInputChange={this.handleInputChange}
            name={this.state.formValues.name}
            email={this.state.formValues.email}
          />
          <SubtitleSpan>
            *En t'abonnant à cette infolettre, tu t'abonnes également à celle du
            ministère de l’auteure, Veille sur ton cœur.
          </SubtitleSpan>
        </GetStartedContainer>
      </StyledSection>
    )
  }
}

const StyledSection = styled(Section)`
  background-color: ${(props) =>
    props.bgLight ? props.theme.color.background.light : "none"};
  // clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

const SubtitleSpan = styled.span`
  ${(props) => props.theme.font_size.xxsmall}
  padding-top: 16px;
  font-size: 14px;
  color: ${(props) => props.theme.color.primary};
  margin-left: 1rem;
  margin-right: 1rem;
`
