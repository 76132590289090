import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
// import { NavItem } from "../common/navigation/styles"

import { Container } from "../common/layout/Containers"
import Socials from "../SocialList"

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
      {/* <FooterColumn>
        <span>Resources</span>
        <ul>
          <li>Blog</li>
        </ul>
      </FooterColumn> */}
      {/* <FooterColumn>
        <span>Veille sur ton coeur</span>
        <ul>
          <li>À propos de nous</li>
        </ul>
      </FooterColumn> */}
      <FooterColumn>
        <span>Suivez-nous sur</span>
        <Socials follow />
      </FooterColumn>
      <FooterColumn>
        <ul>
          <li>
            <NavLink to="/blog/">Mon journal</NavLink>
          </li>
          <li>
            <NavLink to="/faq/">FAQ</NavLink>
          </li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <a
          href="https://jeveillesurmoncoeur.com/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../../assets/images/book/veille-sur-ton-coeur.png"
            alt="photo de l'auteur du livre 31 jours de prière pour ton futur mari"
            width={200}
            placeholder="tracedSVG"
          />
        </a>
      </FooterColumn>
    </FooterColumnContainer>
    <BrandContainer>
      <Logo>
        <span>
          &copy;{` VERS LE MARI DE MA DESTINÉE `}
          {new Date().getFullYear()}
        </span>
      </Logo>
    </BrandContainer>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.background.secondary};
  // margin: 80px 0 0;
  padding: 80px 0 80px;
`

const Logo = styled.div`
  font-family: ${(props) => props.theme.font.extrabold};
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${(props) => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${(props) => props.theme.font.normal};
      font-size: 15px;
    }
  }
`

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.color.primary};
  &:hover: {
    color: ${(props) => props.theme.color.over};
  }
`

export default Footer
