import styled from "styled-components"
import { Link } from "gatsby"
import { Container } from "../common/layout/Containers"

export const LinkStyled = styled(Link)`
  align-items: center;
  display: flex;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
    background: ${(props) => props.theme.color.over};
    transition: 0.3s;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

export const TryItButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
    background: ${(props) => props.theme.color.over};
    transition: 0.3s;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
`

export const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 0 40px;
`

export const FeatureText = styled.p`
  font-size: 1.25rem;
  line-height: 1.75rem;
  strong {
    font-family: "HK Grotesk Bold";
  }
  a {
    color: var(--clr-primary-8);
    text-decoration: none;
    :hover {
      color: var(--clr-primary-5);
    }
  }
`

export const StyledContainer = styled(Container)`
  margin-top: 5rem;
`

export const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  margin: 0 auto 40px;
  text-align: center;
`

export const Subtitle = styled.span`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
  margin-top: 2rem;
`
